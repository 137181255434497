<template>
  <v-container fluid>
    <PageHeader showBackButton :isLoading="$store.getters.isLoading"> </PageHeader>
    <v-form ref="dataForm" @submit.prevent="save">
      <v-card class="mb-8">
        <v-card-text>
          <v-row class="ma-0">
            <v-col cols="12" md="6">
              <form-input label="用戶組別名稱" :fieldValue.sync="formData.name" required />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <div class="d-flex algin-center">
        <v-btn depressed color="primary accent-4" class="white--text" type="submit" :loading="$store.getters.isLoading"
          >儲存</v-btn
        >
        <v-btn
          depressed
          color="error accent-4 ml-2"
          class="white--text"
          type="button"
          :loading="$store.getters.isLoading"
          @click="handleDelete()"
          v-if="id !== null"
          >刪除</v-btn
        >
      </div>
    </v-form>

    <confirm-dialog ref="confirmDeleteDialog" :loading="$store.getters.isLoading" disableCloseConfirmed />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import FormInput from '@/components/form/FormInput.vue'
import FormSelect from '@/components/form/FormSelect.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'

export default {
  name: 'SettingDetail',
  components: {
    FormInput,
    FormSelect,
    ConfirmDialog,
  },
  computed: {
    id() {
      const id = parseInt(this.$route.params.id)
      if (!isNaN(id)) {
        return id
      }

      return null
    },
  },
  data: () => ({
    formData: {
      name: '',
    },
  }),

  methods: {
    ...mapActions(['setAlert']),
    async getDetail() {
      try {
        const user = this.getCurrentUserData()
        const data = await this.$Fetcher.GetStudentGroupById(this.id, user.id, user.token)
        for (const key in this.formData) {
          if (this.$validate.DataValid(data[key])) {
            this.$set(this.formData, key, data[key])
          }
        }
      } catch (err) {
        this.$common.error(err)
        if (err) {
          this.setAlert({
            type: 'error',
            message: err,
            redirect: 'Setting',
          })
        } else {
          this.setAlert({
            message: 'noData',
            redirect: 'Setting',
          })
        }
      } finally {
        this.$store.dispatch('setInitLoading', false)
      }
    },

    async handleDelete() {
      const confirm = await this.$refs['confirmDeleteDialog'].show()
      if (confirm) {
        this.$store.dispatch('setLoading', true)
        try {
          const user = this.getCurrentUserData()
          await this.$Fetcher.DeleteStudentGroup(this.id, user.id, user.token)
          this.$refs['confirmDeleteDialog'].hide()
          this.$store.dispatch('toggleSnack', {
            type: 'success',
            message: '刪除成功',
            redirect: 'Setting',
          })
        } catch (err) {
          if (err) {
            this.setAlert({
              type: 'error',
              message: err,
            })
          }
          this.$store.dispatch('setLoading', false)
        }
      }
    },

    async save() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleSnack', {
          message: 'processing',
        })
        return
      }

      const valid = await this.$refs['dataForm'].validate()
      if (!valid) {
        this.$store.dispatch('toggleSnack', {
          message: 'required',
        })
        return
      }
      this.$store.dispatch('setLoading', true)
      const payload = this.$common.duplicateData(this.formData)
      const user = this.getCurrentUserData()

      if (this.$validate.DataValid(this.id)) {
        // edit
        try {
          await this.$Fetcher.UpdateStudentGroup(this.id, payload, user.id, user.token)
          this.$store.dispatch('setDataIsUpdated', false)
          this.$store.dispatch('toggleSnack', {
            type: 'success',
            message: '儲存成功',
            refresh: true,
          })
        } catch (err) {
          this.setAlert({
            title: '儲存失敗',
            message: err,
            type: 'error',
          })
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      } else {
        try {
          await this.$Fetcher.NewStudentGroup(payload, user.id, user.token)
          this.$store.dispatch('setDataIsUpdated', false)
          this.$store.dispatch('toggleSnack', {
            type: 'success',
            message: '新增成功',
            redirect: 'Setting',
          })
        } catch (err) {
          this.setAlert({
            title: '新增失敗',
            message: err,
            type: 'error',
          })
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      }
    },
  },
  async created() {
    this.$store.dispatch('setInitLoading', true)
    if (this.$validate.DataValid(this.id)) {
      this.getDetail()
    } else {
      this.$store.dispatch('setInitLoading', false)
    }
  },

  // ------ navigation guard ------
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters.isLoading) {
      this.$store.dispatch('toggleSnack', {
        show: true,
        message: 'processing',
        type: 'error',
        refresh: false,
        redirect: '',
      })
      next(false)
    } else {
      next()
    }
  },
}
</script>
